import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import styles from './donate.module.scss';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none'
  },
  input: {
    display: 'none'
  }
}));

const Donate = props => {
  const classes = useStyles();
  const donateText = `Write2Congress would not be possible without the ProPublica Congress API and the @unitedstates project.  
    If you enjoyed this app or find it useful, please consider donating or contributing to these great organizations.`;

  const proPublicaLink = `https://donate.propublica.org/`;
  const unitedstatesLink = `https://theunitedstates.io/`;

  return (
    <div className={styles.contentContainer}>
      <div className={styles.contentText}>{donateText}</div>
      <br />
      <br />
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        href={proPublicaLink}
      >
        ProPublica
      </Button>

      <Button
        variant="text"
        color="primary"
        className={classes.button}
        href={unitedstatesLink}
      >
        @unitedstates project
      </Button>
    </div>
  );
};

export default Donate;
