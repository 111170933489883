import React from 'react';
import styles from './voteCard.module.scss';
import DataWithLabel from '../DataWithLabel';

const VoteCard = props => {
  //General Vote Data
  const vote = props.vote;
  const chamber = vote.chamber;
  const congressNum = vote.congress;
  const voteQuestion = vote.question_text;
  const description = vote.description;
  const result = vote.result;
  const date = vote.date;
  const positionTaken = vote.position;
  const yesVotes = vote.total.yes;
  const noVotes = vote.total.no;
  const presentVotes = vote.total.present;
  const notVoted = vote.total.not_voting;
  const voteUrl = vote.url;
  const voteType = vote.vote_type;
  const isNominationVote =
    voteType === '1/2' || voteQuestion === 'On the Nomination';

  //Bill Votes Data
  const isBillVote = vote.bill !== undefined && vote.bill.billId;
  const billId = vote.bill.bill_id;
  const billNumber = vote.bill.number;
  const billTitle = vote.bill.title;
  const billLastestAction = vote.bill.latest_action;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.titleContainer}>
        {voteQuestion ? voteQuestion : description}
      </div>
      <div className={styles.detailsParentContainer}>
        <div className={styles.detailsContainer}>
          <DataWithLabel
            alwaysStackItems={true}
            label="Vote Question"
            text={voteQuestion}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Description"
            text={description}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date of Vote"
            text={date}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Chamber"
            text={chamber}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Congress"
            text={congressNum}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Vote Result"
            text={result}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Position Taken"
            text={positionTaken}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Yes Votes"
            text={yesVotes}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="No Votes"
            text={noVotes}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Present Votes"
            text={presentVotes}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Not Votes"
            text={notVoted}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Bill Number"
            text={billNumber}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Bill Title"
            text={billTitle}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Bills Last Action"
            text={billLastestAction}
          />
        </div>
      </div>
    </div>
  );
};

export default VoteCard;
