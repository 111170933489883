import React from 'react';
import { LegislatorUtils } from '../../utils';
import ContactCard from '../ContactCard';
import twitterLogo from '../../assets/images/ic_action_social_twitter.png';
import facebookLogo from '../../assets/images/ic_action_social_facebook.png';
import youtubeLogo from '../../assets/images/ic_action_social_youtube.png';
import internetLogo from '../../assets/images/ic_action_ios7_world.png';
import contactFormLogo from '../../assets/images/ic_action_email.png';

function CreatedContactItem(url, imgSrc, label) {
    return {
        url: url,
        imgSrc: imgSrc,
        label: label
    };
}

const LegislatorContactCard = (props) => {
    const twitterContact = CreatedContactItem(LegislatorUtils.getTwitterUrl(props.legislator), twitterLogo, 'Twitter');
    const facebookContact = CreatedContactItem(LegislatorUtils.getFacebookUrl(props.legislator), facebookLogo, 'Facebook');
    const youtubeContact = CreatedContactItem(LegislatorUtils.getYoutubeUrl(props.legislator), youtubeLogo, 'YouTube');
    const websiteContact = CreatedContactItem(LegislatorUtils.getWebsite(props.legislator), internetLogo, 'Website');
    const contactFormContact = CreatedContactItem(LegislatorUtils.getContactFormUrl(props.legislator), contactFormLogo, 'Contact Form');

    const contactList = [
        twitterContact,
        facebookContact,
        youtubeContact,
        websiteContact,
        contactFormContact
    ];

    return (
        <ContactCard className={props.className} title='Contact' contactsArray={contactList} hideLabelForMobile={true} />
    )
}

export default LegislatorContactCard;