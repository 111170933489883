import React from 'react';
import styles from './loadMoreButton.module.scss';
import loadMoreIcon from '../../assets/images/save-24px.svg';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetApp from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
}));

function LoadMoreButton(props) {
  const text = props.isDisabled
    ? 'Loading'
    : props.buttonText
    ? props.buttonText
    : 'Load More';
  const isDisabled = props.isDisabled || false;
  const classes = useStyles();

  const handleOnKeyPress =
    typeof props.onKeyPress === 'function' ? props.onKeyPress : null;

  const handleOnFocus =
    typeof props.onFocus === 'function' ? props.onFocus : null;

  return (
    <div className={styles.parentContainer}>
      <Button
        variant="contained"
        color="primary"
        size={'medium'}
        className={classes.button}
        onClick={handleOnKeyPress}
        onFocus={handleOnFocus}
        disabled={isDisabled}
      >
        {text}
        <GetApp className={classes.rightIcon} />
      </Button>
    </div>
  );
}

export default LoadMoreButton;
