import React, { useState } from 'react';
import './header.module.scss';
import 'bootstrap/dist/css/bootstrap.css';
import write2congressIcon from '../../assets/images/write2congress_icon.png';
import styles from './header.module.scss';

function Header(props) {
  const [navbarIsCollapsed, setNavbarIsCollapsed] = useState(true);

  const classOne = navbarIsCollapsed
    ? `collapse navbar-collapse ${styles.navBarItemContainer}`
    : `collapse navbar-collapse show`;
  const classTwo = navbarIsCollapsed
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right';

  const handleNavbarToggle = () => {
    console.log('dropdown toggle clicked');
    setNavbarIsCollapsed(!navbarIsCollapsed);
  };

  return (
    <nav className={`${styles.header} navbar navbar-expand-lg navbar-dark`}>
      <a className={`${styles.headerText} navbar-brand`} href="/">
        <img
          src={write2congressIcon}
          className={`${styles.headerLogo} d-inline-block align-top`}
          alt="Write 2 Congress icon"
        />
        Write 2 Congress <span>beta</span>
      </a>
      <button
        onClick={handleNavbarToggle}
        className={`${classTwo}`}
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={classOne} id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="/">
              All Legislators
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/searchbills">
              Search Bills
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/latestbills">
              Latest Bills
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/donate">
              Donate
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
