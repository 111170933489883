import React, { useState, useEffect } from 'react';
import { useProPublicaFetchData } from '../../hooks';
import { GeoUtils } from '../../utils';
import LegislatorsViewer from '../../components/LegislatorsViewer';
import SampleSenateMembers from '../../assets/samples/legislators_senate';
import ProPublicaApi from '../../api/proPublicaApi';
import Title from '../../components/Title';
import TextDisplay from '../../components/TextDisplay';

const senatorsCacheKey = 'w2c-senators';
const congressCacheKey = 'w2c-congress';
const lastUpdateCacheKey = 'w2c-lastRefreshDate';

function Home(props) {
  const useTestData = false;
  const useCachedData = true;
  const [legislators, setLegislators] = useState([]);

  const [
    {
      data: senators,
      currentOffset: senatorOffset,
      isLoading: isLoadingSenators,
      isError: isErrorWithSenators,
      isThereMoreResults: isThereMoreSenateResuts
    },
    updateSenateUrl,
    loadMoreSenateData
  ] = useProPublicaFetchData(
    useTestData || useCachedData ? '' : ProPublicaApi.getSenateMembersReqUrl(),
    0,
    ProPublicaApi.getLegislatorsFromResults
  );

  const [
    {
      data: congressMembers,
      currentOffset: congressOffset,
      isLoading: isLoadingCongress,
      isError: isErrorWithCongresss,
      isThereMoreResults: isThereMoreCongressResuts
    },
    updateCongressUrl,
    loadMoreCongressData
  ] = useProPublicaFetchData(
    useTestData || useCachedData ? '' : ProPublicaApi.getHouseMembersReqUrl(),
    0,
    ProPublicaApi.getLegislatorsFromResults
  );

  useEffect(() => {
    if (!senators || senators.length < 1) {
      console.log('useEffect for data fired but senators is EMPTY. RETURNING');
      return;
    }
    console.log(
      `useEffect for senate data fired. Updating legislators. Number of retrieved legislators ${senators.length} `
    );
    const updatedLegislators = addStateDataToLegislators(senators);
    saveLegilatorDataToLocalStorage(senatorsCacheKey, updatedLegislators);
    setLegislators(l => joinAndSortLegislators(l, updatedLegislators));
  }, [senators]);

  useEffect(() => {
    if (!congressMembers || congressMembers.length < 1) {
      console.log(
        'useEffect for data fired but congressMembers is EMPTY. RETURNING'
      );
      return;
    }
    console.log(
      `useEffect for congressMembers data fired. Updating legislators. Number of retrieved legislators ${congressMembers.length}.`
    );
    const updatedLegislators = addStateDataToLegislators(congressMembers);
    saveLegilatorDataToLocalStorage(congressCacheKey, updatedLegislators);
    setLegislators(l => joinAndSortLegislators(l, updatedLegislators));
  }, [congressMembers]);

  useEffect(() => {
    if (useTestData) {
      console.log('Setting test data');
      setLegislators(SampleSenateMembers.results[0].members);
    }
  }, [useTestData]);

  useEffect(() => {
    if (!useCachedData || !hasLocalStorage) {
      console.log(
        `useCachedData is ${useCachedData} & hasLocalStorage is ${hasLocalStorage}. Updating urls to retrieve data`
      );
      updateSenateUrl(ProPublicaApi.getSenateMembersReqUrl());
    } else {
      const cachedSenatorData = localStorage.getItem(senatorsCacheKey);
      if (cachedSenatorData) {
        const cachedSenators = JSON.parse(cachedSenatorData);
        console.log(`setting ${cachedSenators.length} cached senators`);
        setLegislators(l => joinAndSortLegislators(l, cachedSenators));
      } else {
        updateSenateUrl(ProPublicaApi.getSenateMembersReqUrl());
      }

      const cachedCongressData = localStorage.getItem(congressCacheKey);
      if (cachedCongressData) {
        const cachedCongress = JSON.parse(cachedCongressData);
        console.log(`setting ${cachedCongress.length}  cached Congress`);
        setLegislators(l => joinAndSortLegislators(l, cachedCongress));
      } else {
        updateCongressUrl(ProPublicaApi.getHouseMembersReqUrl());
      }
    }
  }, [useCachedData]);

  const joinAndSortLegislators = (origLegislators, newLegislators) => {
    // let sortedLegislators = origLegislators.concat(newLegislators);
    let sortedLegislators = [...origLegislators, ...newLegislators];

    sortedLegislators = sortedLegislators.sort(function(a, b) {
      return a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
    });
    sortedLegislators = sortedLegislators.filter(
      (l, index, legislatorArray) => {
        if (index > 0 && l.id === legislatorArray[index - 1].id) return false;
        else return true;
      }
    );

    if (
      sortedLegislators.length <
      origLegislators.length + newLegislators.length
    )
      console.log(
        `Lenght of sorted/filtered array is ${
          sortedLegislators.length
        }. Prev lenght of combined elements ${origLegislators.length +
          newLegislators.length}`
      );

    return sortedLegislators;
  };

  const addStateDataToLegislators = legislatorArray => {
    return legislatorArray.map(l => {
      if (!l.state_full_name && l.state)
        l.state_full_name = GeoUtils.getStateFullName(l.state);

      return l;
    });
  };

  const saveLegilatorDataToLocalStorage = (storageKey, legislators) => {
    localStorage.setItem(storageKey, JSON.stringify(legislators));
    localStorage.setItem(lastUpdateCacheKey, new Date().toString());
  };

  const hasLocalStorage = typeof localStorage !== undefined;

  const refreshLegislators = () => {
    console.log('Refreshing Legislator Data');
    updateCongressUrl(ProPublicaApi.getHouseMembersReqUrl());
    updateSenateUrl(ProPublicaApi.getSenateMembersReqUrl());
  };

  if (
    isErrorWithSenators ||
    isLoadingSenators ||
    (!legislators || legislators.length < 1)
  ) {
    const diplayText = isLoadingSenators
      ? 'Retreiving Legislators...'
      : isErrorWithSenators
      ? 'Error encountered.'
      : 'Loading....';

    return <TextDisplay text={diplayText} />;
  } else
    return (
      <div>
        <Title text="Legislators" />
        <LegislatorsViewer
          legislators={legislators}
          onRefreshRequested={refreshLegislators}
        />
      </div>
    );
}

export default Home;
