import React  from 'react';
import styles from './dataWithLabel.module.scss';

function DataWithLabel(props) {
    const label = props.label;
    const text = props.text;
    const showLabel = (text && text !== '');
    const className = `
        ${styles.container} 
        ${props.alwaysStackItems
            ? styles.alwaysStackItems 
            : styles.stackForSmallScreen}`;

    const textCss = props.capitalizeText
        ? styles.capitalizeText
        : '';

    if(showLabel) {
        return (
            <div className={className}>
                <strong>{label}: </strong><div className={textCss}>{text}</div>
            </div>
        );
    }

    return (
        <div></div>
    )
}

export default DataWithLabel;