import React from 'react';
import BillsViewerWithFilterAndLoadMore from '../../components/BillsViewerWithFilterAndLoadMore';
import { useProPublicaFetchData } from '../../hooks';
import { ProPublicaApi } from '../../api';

function LatestBills(props) {
  console.log('LatestBills Component is executing');
  const initialUrl = ProPublicaApi.getLatestBillsUrl;
  const initialOffset = 0;
  const [
    { data, currentOffset, isLoading, isError, isThereMoreResults },
    updateUrl,
    loadMoreBills
  ] = useProPublicaFetchData(
    initialUrl,
    initialOffset,
    ProPublicaApi.getBillsFromResult
  );
  const title = `Recently Updated Bills`;

  function loadMoreClicked(event) {
    console.log('Load more cliked');
    loadMoreBills();
  }

  return (
    <BillsViewerWithFilterAndLoadMore
      title={title}
      bills={data}
      showLoadMoreButton={isThereMoreResults}
      onLoadMoreClick={loadMoreClicked}
      placeholderSearchInputText={'Enter filter criteria...'}
      isError={isError}
      errorText={'Error occurred loading latest bills...'}
      isLoading={isLoading}
      loadingText={'Loading latest bills...'}
    />
  );
}

export default LatestBills;
