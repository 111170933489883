import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';

const ConfirmationModalDialog = props => {
  const { isDialogOpen, title, text, onConfirm, onCancel, onClose } = props;

  const handleOnConfirm = e => {
    onConfirm(e);
  };

  const handleOnCancel = e => {
    onCancel(e);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={isDialogOpen}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancel} color="primary">
          Disagree
        </Button>
        <Button onClick={handleOnConfirm} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModalDialog;
