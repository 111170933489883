import React, { useState, useEffect } from 'react';
import styles from './LegislatorsViewer.module.scss';
import LegislatorCard from '../LegislatorCard';
import SearchBar from '../SearchBar';
import Modal from '@material-ui/core/Modal';
import LegislatorDetailedView from '../../components/LegislatorDetailedView';
import { Button } from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';
import ConfirmationModalDialog from '../../components/ConfirmationModalDialog';
import { CommonUtils } from '../../utils';

const lastUpdateCacheKey = 'w2c-lastRefreshDate';
const daysToShowWarningUntil = 2;

const LegislatorsViewer = props => {
  console.log('Rendering LegislatorsViewer');
  const legislators = props.legislators;
  const onRefreshRequested = props.onRefreshRequested;
  const [filteredLegislators, setFilteredLegislators] = useState(legislators);
  const [showModal, setShowModal] = useState(false);
  const [selectedLegislator, setSelectedLegisaltor] = useState(null);
  const [isRefreshConfirmationOpen, setIsRefreshConfirmationOpen] = useState(
    false
  );

  function handleSearchTextChange(newText) {
    const value = newText;

    let newFilteredLegislators = legislators.filter(function(l) {
      const valueToLowerCase = value.toLowerCase();
      return (
        l.first_name.toLowerCase().includes(valueToLowerCase) ||
        l.last_name.toLowerCase().includes(valueToLowerCase) ||
        (l.state_full_name &&
          l.state_full_name.toLowerCase().includes(valueToLowerCase))
      );
    });

    setFilteredLegislators(newFilteredLegislators);
  }

  useEffect(() => {
    console.log('SelectLegislator OnEffect');
    if (selectedLegislator != null) {
      setShowModal(true);
    }
  }, [selectedLegislator]);

  const handleOnLegislatorClick = l => {
    setSelectedLegisaltor(l);
  };

  const closeModal = () => setShowModal(false);

  const handleRefreshClicked = e => {
    if (shouldShowRefreshWarning()) setIsRefreshConfirmationOpen(true);
    else onRefreshRequested();
  };

  const onRefreshedConfirmed = () => {
    console.log(`Refresh was confirmed!`);
    setIsRefreshConfirmationOpen(false);
    onRefreshRequested();
  };

  const onRefreshCancelled = () => {
    console.log(`Confirm was cancelled was confirmed!`);
    setIsRefreshConfirmationOpen(false);
  };

  const getLastUpdateTimeFromCache = () => {
    return Date.parse(localStorage.getItem(lastUpdateCacheKey)) || null;
  };

  const shouldShowRefreshWarning = () => {
    const lastRefreshDate = getLastUpdateTimeFromCache();
    if (!lastRefreshDate) return true;

    const daysSinceRefresh = CommonUtils.differenceInDays(
      lastRefreshDate,
      new Date()
    );
    return daysSinceRefresh < daysToShowWarningUntil;
  };

  return (
    <div>
      <ConfirmationModalDialog
        isDialogOpen={isRefreshConfirmationOpen}
        title={'Confirm Refresh'}
        text={`It has been less than ${daysToShowWarningUntil} days since last refresh. Are you sure you want to refresh?`}
        onConfirm={onRefreshedConfirmed}
        onCancel={onRefreshCancelled}
      />
      <Modal
        open={showModal}
        onBackdropClick={closeModal}
        onEscapeKeyDown={closeModal}
      >
        <div className={styles.modalContainer}>
          <LegislatorDetailedView legislator={selectedLegislator} />
        </div>
      </Modal>
      <div className={styles.searchBarContainer}>
        <SearchBar
          showFiterIcon={true}
          onTextChange={handleSearchTextChange}
          placeholderText="Filter by name or state..."
        />
        <Button onClick={handleRefreshClicked} className={styles.refreshButton}>
          <Refresh />
        </Button>
      </div>
      <div>
        {filteredLegislators.map(legislator => (
          <LegislatorCard
            key={legislator.id}
            legislator={legislator}
            handleOnLegislatorClick={handleOnLegislatorClick}
          />
        ))}
      </div>
    </div>
  );
};

export default LegislatorsViewer;
