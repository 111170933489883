import React from 'react';
import { LegislatorUtils } from '../../utils';
import DataWithLabel from '../DataWithLabel';
import styles from './legislatorTextInfo.module.scss';

const LegislatorTextInfo = props => {
  const cssToApply = `${styles.detailsContainer} ${props.className || ''}`;
  const stateAbbreviation = LegislatorUtils.getState(props.legislator);
  const stateFullName = props.legislator.state_full_name || stateAbbreviation;

  return (
    <div className={cssToApply}>
      <DataWithLabel label="State" text={stateFullName} />
      <DataWithLabel
        label="District"
        text={LegislatorUtils.getDistrict(props.legislator)}
      />
      <DataWithLabel
        label="Party"
        text={LegislatorUtils.getParty(props.legislator)}
      />
      <DataWithLabel
        label="Date of Birth"
        text={LegislatorUtils.getDobFormatted(props.legislator)}
      />
      <DataWithLabel
        label="Age"
        text={LegislatorUtils.getAge(props.legislator)}
      />
      <DataWithLabel
        label="Gender"
        text={LegislatorUtils.getGender(props.legislator)}
      />
      <DataWithLabel
        label="Next election"
        text={LegislatorUtils.getNextElection(props.legislator)}
      />
      <DataWithLabel
        label="Total Votes"
        text={LegislatorUtils.getTotalVotes(props.legislator)}
      />
      <DataWithLabel
        label="Missed Votes"
        text={LegislatorUtils.getMissedVotes(props.legislator)}
      />
      <DataWithLabel
        label="Missed Votes Percentage"
        text={LegislatorUtils.getMissedVotesPercent(props.legislator)}
      />
      <DataWithLabel
        label="Votes With Party Percentage"
        text={LegislatorUtils.getVotesWithPartyPercentage(props.legislator)}
      />
    </div>
  );
};

export default LegislatorTextInfo;
