import React from 'react';
import styles from './legislatorDetailedView.module.scss';
import { LegislatorUtils } from '../../utils';
import Title from '../Title';
// import LegislatorImage from '../LegislatorImage';
// import LegislatorTextInfo from '../LegislatorTextInfo';
// import LegislatorContactCard from '../LegislatorContactCard';
import BillsSponsored from '../BillsSponsored';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import VotesByLegislator from '../VotesByLegislator';

const LegislatorDetailedView = (props) => {
    console.log('Rendering LegislatorDetailedView');
    
    const viewerStyle =  {
        // 'max-height': '50vh', 
        'maxHeight': '50vh', 
        'overflowY': 'scroll'
    };
    const legislator = props.legislator;
    const legislatorDisplayTitle  = 
        `${LegislatorUtils.getShortTitle(props.legislator) || ''} ${LegislatorUtils.getFullName(props.legislator)}`;

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand'
          };

    return ( 
        <div className={styles.parentContainer}>
            <Title className={styles.title} text={legislatorDisplayTitle}  />
            {/* 
            <LegislatorImage className={styles.portrait} legislator={legislator} />
            <div className={styles.textDetailsContainer} >
                <LegislatorTextInfo legislator={legislator} />
            </div>
            <LegislatorContactCard className={styles.contactCard} legislator={legislator} /> */}
            {/* <div className={styles.carousselContainer}> */}
            <div>
                <Slider {...settings} >
                    <div>
                        <VotesByLegislator legislatorId={legislator.id} viewerStyle={viewerStyle} />
                    </div>
                    <div>
                        <BillsSponsored legislatorId={legislator.id} billViewerStyle={viewerStyle} />
                    </div>
                    <div>
                        <BillsSponsored legislatorId={legislator.id} useCosponsorMode={true} billViewerStyle={viewerStyle}  />
                    </div>
                </Slider>
            </div>
        </div>
     );
}
 
export default LegislatorDetailedView;