import React from 'react';
import styles from './footer.module.scss';

function Footer(props) {
  return (
    <div className={styles.footerContainer}>
      <p>&copy; 2019 Write2Congress</p>
    </div>
  );
}

export default Footer;
