import React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HolyGrailLayout from './layouts/HolyGrailPageLayout';
import Home from './pages/Home';
import LatestBills from './pages/LatestBills';
import SearchBills from './pages/SearchBills';
import Donate from './pages/Donate';

function App() {
  return (
    <HolyGrailLayout>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/LatestBills" component={LatestBills} />
          <Route path="/SearchBills" component={SearchBills} />
          <Route path="/Donate" component={Donate} />
        </Switch>
      </BrowserRouter>
    </HolyGrailLayout>
  );
}

export default App;
