import React, { useState } from 'react';
import { ProPublicaApi } from '../../api';
import { useProPublicaFetchData } from '../../hooks';
import BillsViewerWithFilterAndLoadMore from '../../components/BillsViewerWithFilterAndLoadMore';

function SearchBills(props) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [lastSearchedTerm, setLastSearchedTerm] = useState(null);
  const [title, setTitle] = useState('Search Bills');
  const [
    { data, currentOffset, isLoading, isError, isThereMoreResults },
    updateUrl,
    loadMoreBills,
    resetOffset
  ] = useProPublicaFetchData(null, 0, ProPublicaApi.getBillsFromResult);

  const showLoadMoreButton = data && data.length > 0 && isThereMoreResults;
  let emptyBillsText =
    lastSearchedTerm === null
      ? 'Enter search'
      : isLoading
      ? 'Loading....'
      : 'No bills found';

  const handleSearchTermUpdate = newText => {
    const newSearchTerm = newText;
    console.log(`updating search term to : ${newSearchTerm}`);
    setSearchTerm(newSearchTerm);
  };

  const handleSearchClicked = function() {
    if (lastSearchedTerm === searchTerm) {
      console.log(
        `Search term ${lastSearchedTerm} has not changed. Not performing search`
      );
    } else if (!searchTerm || !searchTerm.trim()) {
      console.log(`Not processing current empty search term: ${searchTerm}`);
    } else {
      const searchUrl = ProPublicaApi.getSearchBillsUrl(searchTerm);
      console.log(`About to update url for Search Bills to ${searchUrl}`);
      setLastSearchedTerm(searchTerm);
      setTitle(`${searchTerm} bills`);

      //TODO RM: This will cause to updated and two possible
      //API calls, need to find a way around this
      resetOffset();
      updateUrl(searchUrl);
    }
  };

  const handleOnLoadMoreClick = () => {
    console.log('Load more clicked....loading more bills');
    loadMoreBills();
  };

  return (
    <BillsViewerWithFilterAndLoadMore
      title={title}
      bills={data}
      emptyBillsText={emptyBillsText}
      placeholderSearchInputText={'Enter filter term...'}
      useSearchMode={true}
      showLoadMoreButton={showLoadMoreButton}
      onLoadMoreClick={handleOnLoadMoreClick}
      onSearchTextChange={handleSearchTermUpdate}
      onSearchButtonClicked={handleSearchClicked}
      onEnterKeyPressed={handleSearchClicked}
      isError={isError}
      errorText={'Error occurred searching bills'}
      isLoading={isLoading}
      loadingText={'Searching bills...'}
    />
  );
}

export default SearchBills;
