import React from 'react';
import { LegislatorUtils } from '../../utils';
import LegislatorContactCard from '../LegislatorContactCard';
import LegislatorImage from '../LegislatorImage';
import LegislatorTextInfo from '../LegislatorTextInfo';
import styles from './legislatorCard.module.scss';
import 'bootstrap/dist/css/bootstrap.css';

function LegislatorCard(props) {
  const stateAbbreviation = LegislatorUtils.getState(props.legislator);
  const legislatorDisplayTitle = `${LegislatorUtils.getShortTitle(
    props.legislator
  ) || ''} ${LegislatorUtils.getFullName(props.legislator)} 
        ${stateAbbreviation ? ' (' + stateAbbreviation + ')' : ''}`;

  const handleOnLegislatorClick = () => {
    props.handleOnLegislatorClick(props.legislator);
  };

  return (
    <div className={styles.cardContainer} onClick={handleOnLegislatorClick}>
      <div className={styles.titleContainer}>{legislatorDisplayTitle}</div>
      <LegislatorImage
        className={styles.cardPortraitParent}
        legislator={props.legislator}
      />
      <div className={styles.detailsParentContainer}>
        <LegislatorTextInfo legislator={props.legislator} />
      </div>
      <div className={styles.contactInfoParentContainer}>
        <LegislatorContactCard legislator={props.legislator} />
      </div>
    </div>
  );
}

export default LegislatorCard;
