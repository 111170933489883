import React from 'react';
import styles from './TextDiplay.module.scss';

function TextDisplay (props) {
    const showText = props.text && props.text.trim();

    if(!showText)
        return;

    return (
        <div className={styles.textContainer}>
            {props.text}
        </div>
    )
}

export default TextDisplay