import React from 'react';
import styles from './title.module.scss';

const Title = (props) => {
    const cssToAppy = `${styles.title} ${props.className || ''}`

    return ( 
        <div className={cssToAppy}>
            {props.text}
        </div>
     );
}
 
export default Title;
