const UsIoApi = {
    baseUrl: "http://theunitedstates.io/images/congress",
    //Possible options: 450x550 and original (typically 675x825, but can vary)
    defaultImageSize: "225x275",

    getLegislatorImageUrlByBioId: function(bioId) {
        return bioId
            ? `${this.baseUrl}/${this.defaultImageSize}/${bioId}.jpg`
            : null;
    }
}

export default UsIoApi;