import React from 'react';
import styles from './contactCard.module.scss';

function CreatedContactItem(url, imgSrc, label, css) {
    if(url) {
        return (
            <div className={css} key={label} >
                <a href={url} >
                    <img src={imgSrc} alt='Contact Info' /><span>{label}</span>
                </a> 
            </div>)
    } else
        return false;
}

function ContactCard (props) {
    const title = props.title || 'Contact';
    const contactInfoList = props.contactsArray || [];
    const contactItemCss = `
        ${styles.contactItem} 
        ${props.hideLabelForMobile
            ? styles.hideLabelOnMobile 
            : ''}`;

    return (
        <div className={`${styles.contactCardContainer} ${props.className || ''}`}>
            <h6>{title}</h6>
            <div className={styles.contactDetailsContainer}>
                {contactInfoList.map(contact =>
                    CreatedContactItem(contact.url, contact.imgSrc, contact.label, contactItemCss))}
            </div>
        </div>
    );
}

export default ContactCard;
