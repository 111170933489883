import React, { useState, useEffect } from 'react';
import styles from './votesByLegislator.module.scss';
import { ProPublicaApi } from '../../api';
import { useProPublicaFetchData } from '../../hooks';
import SampleVotes from '../../assets/samples/votes_recent';
import VoteViewer from '../VoteViewer';
import LoadMoreButton from '../LoadMoreButton';
import SearchBar from '../SearchBar';
import Title from '../Title';

const VotesByLegislator = props => {
  //TODO RM Find why getVotesBy legislator is called 3 times when inside a modal & slider

  console.log('VotesByLegislator Component is executing');
  const useTestData = false;
  const initialUrl = useTestData
    ? ''
    : ProPublicaApi.getVotesByLegislator(props.legislatorId);
  const [
    { data, currentOffset, isLoading, isError, isThereMoreResults },
    updateUrl,
    loadMoreData
  ] = useProPublicaFetchData(initialUrl, 0, ProPublicaApi.getVotesFromResults);
  const [filteredVotes, setFilteredVotes] = useState(data);
  const viewerStyle = props.viewerStyle;
  const emptyTextToShow =
    isLoading || isError
      ? isLoading
        ? 'Loading votes...'
        : 'Error retrieving votes'
      : 'No votes to show ';

  const handleFilterTextChange = newText => {
    console.log(`handleFilterTextChange ${newText}`);

    const newFilteredVotes = data.filter(function(v) {
      const valueToLowerCase = newText.toLowerCase();
      return (
        v.description.toLowerCase().includes(valueToLowerCase) ||
        (v.question_text &&
          v.question_text.toLowerCase().includes(valueToLowerCase)) ||
        (v.bill &&
          v.bill.title &&
          v.bill.title.toLowerCase().includes(valueToLowerCase))
      );
    });
    setFilteredVotes(newFilteredVotes);
  };

  useEffect(() => {
    setFilteredVotes(data);
  }, [data]);

  useEffect(() => {
    console.log(`useEffect for VotesByLegislator FOR USERTESTDATA is running`);
    if (useTestData) {
      setFilteredVotes(SampleVotes.results.votes);
    }
  }, [useTestData]);

  return (
    <>
      <Title text={'Votes'} />
      <div className={styles.searchBarContainer}>
        <SearchBar
          placeholderText={'Filter votes'}
          showFiterIcon={true}
          onTextChange={handleFilterTextChange}
          isDisabled={isLoading}
        />
      </div>
      <VoteViewer
        votes={filteredVotes}
        viewerStyle={viewerStyle}
        emptyText={emptyTextToShow}
      />
      {isThereMoreResults && (
        <LoadMoreButton onKeyPress={loadMoreData} isDisabled={isLoading} />
      )}
    </>
  );
};

export default VotesByLegislator;
