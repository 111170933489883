import React, { useEffect, useState } from 'react';
import styles from './BillsViewerWithFilterAndLoadMore.module.scss';
import SearchBar from '../SearchBar';
import BillsViewer from '../BillsViewer';
import LoadMoreButton from '../LoadMoreButton';
import Title from '../Title';

const BillsViewerWithFilterAndLoadMore = props => {
  const originalBills = props.bills;
  const [filteredBills, setFilteredBills] = useState(originalBills);

  const title = props.title;
  const showLoadMoreButton =
    props.showLoadMoreButton && filteredBills.length > 0;
  const useSearchMode = props.useSearchMode;
  const isLoading = props.isLoading;
  const isError = props.isError;
  const errorText = props.errorText || 'Error occured.......';
  const loadingText = props.loadingText || 'Loding....';
  const emptyBillsText =
    isError || isLoading
      ? isLoading
        ? loadingText
        : errorText
      : props.emptyBillsText || 'No bills to show';
  const placeholderTextForSearch =
    props.placeholderSearchInputText ||
    (useSearchMode ? 'Search bills...' : 'Filter bills...');
  const billViewerStyle =
    props.billViewerStyle === undefined ? {} : props.billViewerStyle;

  const handleOnLoadMoreClick =
    typeof props.onLoadMoreClick === 'function' ? props.onLoadMoreClick : null;

  const handleSearchTextChange =
    typeof props.onSearchTextChange === 'function'
      ? props.onSearchTextChange
      : defaultHandleSearchTextChange;

  const handleOnSearchButtonClicked =
    typeof props.onSearchButtonClicked === 'function'
      ? props.onSearchButtonClicked
      : null;

  const handleOnEnterKeyPressed =
    typeof props.onEnterKeyPressed === 'function'
      ? props.onEnterKeyPressed
      : null;

  function defaultHandleSearchTextChange(newText) {
    const value = newText;
    console.log(`handleSearchTextChange ${value}`);

    let newFilteredBills = originalBills.filter(function(b) {
      const valueToLowerCase = value.toLowerCase();
      return (
        b.short_title.toLowerCase().includes(valueToLowerCase) ||
        b.number.toLowerCase().includes(valueToLowerCase)
      );
    });

    setFilteredBills(newFilteredBills);
  }

  useEffect(() => {
    console.log(
      'useEffect For BILLVIEWER is RUNNING!. Prob.Bills must have been updated'
    );
    setFilteredBills(props.bills);
  }, [props.bills]);

  return (
    <div className={styles.pageContainer}>
      <Title text={title} />
      <div className={styles.searchBarContainer}>
        <SearchBar
          onTextChange={handleSearchTextChange}
          onSearchButtonClick={handleOnSearchButtonClicked}
          onEnterKeyPressed={handleOnEnterKeyPressed}
          showFiterIcon={!useSearchMode}
          placeholderText={placeholderTextForSearch}
          isDisabled={isLoading}
        />
      </div>
      <div className={styles.contentContainer} style={billViewerStyle}>
        <BillsViewer bills={filteredBills} emptyText={emptyBillsText} />
      </div>
      {showLoadMoreButton && (
        <LoadMoreButton
          onKeyPress={handleOnLoadMoreClick}
          isDisabled={isLoading}
        ></LoadMoreButton>
      )}
    </div>
  );
};

export default BillsViewerWithFilterAndLoadMore;
