import React from 'react';
import styles from './billsViewer.module.scss';
import BillCard from '../BillCard';
import TextDisplay from '../TextDisplay';

const BillsViewer = (props) => {
    const emptyText = props.emptyText || 'No bills to show';
    const bills = props.bills || [];

    const htmlToShow = (bills && bills.length > 0)
        ? (
            <div className={styles.billViewerContainer}>
                {bills.map(bill => 
                    <BillCard key={bill.bill_id} bill={bill} />)}
            </div>)
        : (
            <TextDisplay text={emptyText} />
        );

    return htmlToShow;
}

export default BillsViewer;