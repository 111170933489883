import React from 'react';
import styles from './voteViewer.module.scss';
import VoteCard from '../VoteCard';
import TextDisplay from '../TextDisplay';

const VoteViewer = (props) => {
    const emptyText = props.emptyText || 'No votes to show';
    const votes = props.votes || [];

    const viewerStyle = props.viewerStyle === undefined
    ? {}
    : props.viewerStyle;

    const htmlToShow = (votes && votes.length > 0)
        ? (
            <div className={styles.voteViewerContainer} style={viewerStyle}>
                {votes.map(vote => 
                    <VoteCard key={vote.vote_uri} vote={vote} /> )}
            </div>)
        : (
            <TextDisplay text={emptyText} />
        );

    return htmlToShow;
}
 
export default VoteViewer;