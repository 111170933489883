import moment from 'moment';
import { UsIoApi } from '../api';
import * as CommonUtils from './commonUtils';

const getlegislatorImgUrl = function (legislator) {

    return legislator.id
        ? UsIoApi.getLegislatorImageUrlByBioId(legislator.id)
        : null;
};

const getLegislatorFullName = function (legislator) {
    return `${legislator.last_name ? legislator.last_name : '(Last name not available)'}, 
        ${legislator.first_name ? legislator.first_name : '(First name not available)'}
        ${legislator.middle_name ? legislator.middle_name : ''}`;
};

const getLegislatorGender = function (legislator) {
    if(!legislator.gender)
        return null;

    switch (legislator.gender.toLowerCase()) {
        case 'm':
        case 'male':
            return 'Male';
        case 'f':
        case 'female':
            return 'Female';
        default:
            return 'Gender not Available';
    }
};

const getLegislatorShortTitle = function (legislator) {
    return returnPropertyIfExist(legislator, 'short_title');
}

const getLegislatorFullTitle = function (legislator) {
    return returnPropertyIfExist(legislator, 'title');
}

const getLegislatorState = function (legislator) {
    return `${legislator.state ? legislator.state : '(State not available)'}`;
};

const getLegislatorDistrict = function (legislator) {
    return returnPropertyIfExist(legislator, 'district');
};

const getLegislatorParty = function (legislator) {
    if(!legislator || !legislator.party)
        return 'Information Unavailable';

    return CommonUtils.getPartyFromPrefix(legislator.party);
}

const getLegislatorDobAsMomentObj = function(legislator)  {
    if(!legislator || !legislator.date_of_birth)
        return null;

    try {
        return moment(legislator.date_of_birth, 'YYYY-MM-DD');
    } catch (error){
        console.log('Error formatting dob: ' + error);
        return null;
    }
}

const getLegislatorDobFormatted = function(legislator) {
    const dob = getLegislatorDobAsMomentObj(legislator);

    return dob
        ? dob.format('LL')
        : null;
}

const getLegislatorAge = function(legislator) {
    const dob = getLegislatorDobAsMomentObj(legislator);
    const now = moment();

    return dob
        ? now.diff(dob, 'years')
        : null;
}

const getLegislatorChamber = function(legislator) {
    if(!legislator || !legislator.title)
        return null;
    switch(legislator.title.toLowerCase().substring(0,3)) {
        case 'rep':
            return 'House';
        case 'sen':
            return 'Senate';
        default:
            return 'Chamber not available';
    }
}

const getLegislatorsNextElection = function (legislator) {
    return returnPropertyIfExist(legislator, 'next_election');
}

const getLegislatorsTotalVotes = function (legislator) {
    return returnPropertyIfExist(legislator, 'total_votes');
}

const getLegislatorsMissedVotes = function (legislator) {
    return returnPropertyIfExist(legislator, 'missed_votes');
}

const getLegislatorsMissedVotesPercent = function (legislator) {
    return returnPropertyIfExist(legislator, 'missed_votes_pct');
}

const getLegislatorsVotesWithPartyPercent = function (legislator) {
    return returnPropertyIfExist(legislator, 'votes_with_party_pct');
}

const getLegislatorTwitterUrl = function (legislator) {
    const url = 'http://twitter.com/';
    const twId = returnPropertyIfExist(legislator, 'twitter_account');

    return twId
        ? `${url}${twId}`
        : null;
}

const getLegislatorFacebookUrl = function (legislator) {
    const url = 'http://facebook.com/';
    const fbId = returnPropertyIfExist(legislator, 'facebook_account');

    return fbId
        ? `${url}${fbId}`
        : null;
}

const getLegislatorYoutubeUrl = function (legislator) {
    const url = '';
    const ytId = returnPropertyIfExist(legislator, 'youtube_account');

    return ytId
        ? `${url}${ytId}`
        : null;
}

const getLegislatorWebsite = function (legislator) {
    return returnPropertyIfExist(legislator, 'url');
}

const getLegislatorContactFormUrl = function (legislator) {
    return returnPropertyIfExist(legislator, 'contact_form');
}

const returnPropertyIfExist = function (obj, property) {
    return property in obj
        ? obj[property]
        : null;
}

export default {
    getImageUrl: getlegislatorImgUrl,
    getFullName: getLegislatorFullName,
    getShortTitle: getLegislatorShortTitle,
    getFullTitle: getLegislatorFullTitle,
    getGender: getLegislatorGender,
    getState: getLegislatorState,
    getDistrict: getLegislatorDistrict,
    getParty: getLegislatorParty,
    getDobAsMomentObj: getLegislatorDobAsMomentObj,
    getDobFormatted: getLegislatorDobFormatted,
    getAge: getLegislatorAge,
    getChamber: getLegislatorChamber,
    getNextElection: getLegislatorsNextElection,
    getTotalVotes: getLegislatorsTotalVotes,
    getMissedVotes: getLegislatorsMissedVotes,
    getMissedVotesPercent: getLegislatorsMissedVotesPercent,
    getVotesWithPartyPercentage: getLegislatorsVotesWithPartyPercent,
    getTwitterUrl: getLegislatorTwitterUrl,
    getFacebookUrl: getLegislatorFacebookUrl,
    getYoutubeUrl: getLegislatorYoutubeUrl,
    getWebsite: getLegislatorWebsite,
    getContactFormUrl: getLegislatorContactFormUrl
}