const baseUrl = 'api/congress/v1';
const currentCongress = '116';
const resultSize = 20;

export const getHouseMembersReqUrl = () =>
  `${baseUrl}/${currentCongress}/house/members.json?`;

export const getSenateMembersReqUrl = () =>
  `${baseUrl}/${currentCongress}/senate/members.json?`;

export const getLatestBillsUrl = () => {
  return `${baseUrl}/${currentCongress}/both/bills/introduced.json?`;
};

export const getSearchBillsUrl = searchTerm => {
  return `${baseUrl}/bills/search.json?query=${searchTerm}`;
};

export const getBillsSponsoredByLegislator = legislatorId => {
  return `${baseUrl}/members/${legislatorId}/bills/introduced.json?`;
  //https://api.propublica.org/congress/v1/members/{member-id}/bills/{type}.json?
  //type 	introduced or updated
};

export const getBillsCoSponsoredByLegislator = legislatorId => {
  return `${baseUrl}/members/${legislatorId}/bills/cosponsored.json?`;
  //https://api.propublica.org/congress/v1/members/Y000033/bills/cosponsored.json?offset=20
};

export const getVotesByLegislator = legislatorId => {
  // https://api.propublica.org/congress/v1/members/Y000033/votes.json
  return `${baseUrl}/members/${legislatorId}/votes.json?`;
};

export const getBillsFromResult = results => {
  return results.bills;
};

export const getVotesFromResults = result => {
  return result.votes;
};

export const getLegislatorsFromResults = result => result.members;

function generateGetRequest(apiUrl, offset, params) {
  if (Array.isArray(params)) params.forEach(param => (apiUrl += '&' + param));

  if (!isNaN(offset)) apiUrl += `&offset=${offset}`;

  console.log(`Generating fetch request to url ${apiUrl}`);

  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}

export const isErrorResp = resp => {
  return !resp || (resp.status && resp.status === 'ERROR');
};

export const generateErrorObjFromResp = resp => {
  var msg = `The request encountered an error. 
        ${resp.status ? 'Status: ' + resp.status : ''}
        Error: ${
          Array.isArray(resp.errors) && resp.errors[0]
            ? resp.errors[0]
            : resp.errors
        }`;
  return new Error(msg);
};

export default {
  resultDefaultSize: resultSize,
  isErrorResp: isErrorResp,
  getErrorFromResp: generateErrorObjFromResp,
  generateGetRequest: generateGetRequest,
  getLatestBillsUrl: getLatestBillsUrl,
  getSearchBillsUrl: getSearchBillsUrl,
  getBillsSponsoredByLegislator,
  getVotesByLegislator,
  getVotesFromResults,
  getBillsFromResult,
  getLegislatorsFromResults,
  getHouseMembersReqUrl,
  getSenateMembersReqUrl
};
