import React, { useState } from 'react';
import styles from './searchBar.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/search-24px.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/close-24px.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/filter-24px.svg';

function SearchBar(props) {
  const [searchText, setSearchText] = useState('');
  const placeholderText = props.placeholderText || 'Search...';
  const isDisabled = props.isDisabled;

  // TODO RM: Check if function exist w/out invoking
  const handleTextChange =
    typeof props.onTextChange === 'function' ? props.onTextChange : null;

  const onTextChange = event => {
    const newTextInput = event.target.value;
    setSearchText(newTextInput);
    if (handleTextChange) handleTextChange(newTextInput);
  };

  const handleOnSearchButtonClick =
    typeof props.onSearchButtonClick === 'function'
      ? props.onSearchButtonClick
      : null;

  const handleOnFocus =
    typeof props.onFocus === 'function' ? props.onFocus : null;

  const handleOnCancelButtonClick =
    typeof props.onCacelButtonClick === 'function'
      ? props.onCacelButtonClick
      : null;

  const onCancel = event => {
    setSearchText('');
    if (handleOnCancelButtonClick) handleOnCancelButtonClick(event);
    if (handleTextChange) handleTextChange('');
  };

  const handleOnKeyPress = event => {
    if (event.key === 'Enter' && handleOnEnterKeyPressed)
      handleOnEnterKeyPressed();
  };

  const handleOnEnterKeyPressed =
    typeof props.onEnterKeyPressed === 'function'
      ? props.onEnterKeyPressed
      : null;

  const disabledCss = isDisabled ? styles.disabled : '';
  const showCancelButton = searchText.length > 0;
  const searchOrFilterIcon = props.showFiterIcon ? (
    <FilterIcon />
  ) : (
    <SearchIcon />
  );

  return (
    <div className={styles.searchBarContainer}>
      <button
        className={`${styles.searchIconBtn} ${disabledCss}`}
        type="button"
        onClick={handleOnSearchButtonClick}
        disabled={isDisabled}
      >
        {searchOrFilterIcon}
      </button>
      <input
        className={`${styles.searchInput} ${disabledCss}`}
        type="search"
        placeholder={placeholderText}
        onChange={onTextChange}
        onKeyPress={handleOnKeyPress}
        onFocus={handleOnFocus}
        id="searchBarInputText"
        value={searchText}
        disabled={isDisabled}
      />
      {showCancelButton && (
        <button
          className={`${styles.cancelIconIconBtn} ${disabledCss}`}
          type="button"
          onClick={onCancel}
        >
          <CancelIcon />
        </button>
      )}
    </div>
  );
}

export default SearchBar;
