const returnPropertyIfExist = function(obj, property) {
  return property in obj ? obj[property] : null;
};

const propertyExists = function(obj, property) {
  return property in obj ? true : false;
};

const differenceInDays = function(oldestDate, newDate) {
  const dt1 = new Date(oldestDate);
  const dt2 = new Date(newDate);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

export const getPartyFromPrefix = function(startingLetter) {
  switch (startingLetter.toLowerCase()) {
    case 'r':
    case 'republican':
      return 'Republican';
    case 'd':
    case 'democrat':
      return 'Democrat';
    case 'i':
    case 'independent':
      return 'Independent';
    case 'l':
    case 'libertarian':
      return 'Libertarian';
    case 'g':
    case 'green':
      return 'Green';
    default:
      return startingLetter;
  }
};

export const getErrorFromFailedFetchResult = result => {
  return new Error(`Error encountered in fetch req. 
        ${result.status ? 'Status: ' + result.status : ''} 
        ${result.statusText ? 'Status Text: ' + result.statusText : ''} 
        ${result.url ? 'URL: ' + result.url : ''}`);
};

export default {
  propertyExists,
  returnPropertyIfExist,
  getPartyFromPrefix,
  getErrorFromFailedFetchResult,
  differenceInDays
};
