import React, { useEffect, useState } from 'react';
import { ProPublicaApi } from '../../api';
import { useProPublicaFetchData } from '../../hooks';
import BillsViewerWithFilterAndLoadMore from '../../components/BillsViewerWithFilterAndLoadMore';

const BillsSponsored = (props) => {
    console.log('BillsSponsored Component is executing');
    const [{ data, currentOffset, isLoading, isError, isThereMoreResults }, updateUrl, loadMoreBills] = useProPublicaFetchData(null, 0, ProPublicaApi.getBillsFromResult);
    const [isErrorStatus, setIsErrorStatus] = useState(isError);
    const legislatorId = props.legislatorId;
    const useCosponsorMode = props.useCosponsorMode;

    useEffect(() => {
        console.log(`Use effect runiing for BillsSponsored`);
        if(!legislatorId) {
            console.log(`Legislator ID is missing. Setting error to True`);
            setIsErrorStatus(true);
        }
        const url = ProPublicaApi.getBillsSponsoredByLegislator(legislatorId);
        updateUrl(url);
    }, [])

    return ( 
        <BillsViewerWithFilterAndLoadMore 
            title={useCosponsorMode ? 'Cosponsored Bills' : 'Sponsored Bills'}
            bills={data}
            emptyBillsText={'No bills found'}
            placeholderSearchInputText={'Filter bills...'}
            useSearchMode={false}
            showLoadMoreButton={data && data.length > 0 && isThereMoreResults}
            onLoadMoreClick={loadMoreBills}
            isError={isErrorStatus}
            errorText={'Error occurred loading bills'}
            isLoading={isLoading}
            loadingText={`Loading ${useCosponsorMode ? 'cosponsored' : 'sponsored'} bills...`}
            billViewerStyle={props.billViewerStyle}
            />
     );
}
 
export default BillsSponsored;