import { useState, useEffect } from 'react';
import { ProPublicaApi } from '../api';
import { CommonUtils } from '../utils';

const useProPublicaFetchData = (
  initialUrl,
  initialOffset,
  getDataFromResultFunc
) => {
  const [data, setData] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(initialOffset);
  const [isThereMoreResults, setIsThereMoreResults] = useState(false);
  const [
    { results, offset, isLoading, isError },
    updateUrl,
    setOffset
  ] = useProPublicaFetch(initialUrl, initialOffset);

  useEffect(() => {
    if (!results) {
      console.log(
        'useProPublicaFetchData UseEffect: Results is null. Returning...'
      );
      return;
    }

    const retrievedOffset = Number(results.offset);
    const numberOfResults = Number(results.num_results);
    const retrievedData = getDataFromResultFunc(results);
    const areThereMoreResults =
      numberOfResults === ProPublicaApi.resultDefaultSize;

    console.log(`SETTING DATA: retrievedOffset IS ${retrievedOffset}`);
    setData(
      retrievedOffset === 0 ? retrievedData : b => b.concat(retrievedData)
    );
    setCurrentOffset(retrievedOffset + numberOfResults);
    setIsThereMoreResults(areThereMoreResults);
  }, [results, getDataFromResultFunc]);

  const loadMoreData = () => {
    console.log(`Loading more data. Setting offset to ${currentOffset}`);
    setOffset(currentOffset);
  };

  const resetOffset = () => {
    console.log(`Resetting offset`);
    setOffset(0);
  };

  return [
    { data, currentOffset, isLoading, isError, isThereMoreResults },
    updateUrl,
    loadMoreData,
    resetOffset
  ];
};

const useProPublicaFetch = (initialUrl, initialOffset) => {
  const [url, setUrl] = useState(initialUrl);
  const [offset, setOffset] = useState(initialOffset);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!url || !url.trim()) {
      console.log('Url is null or empty. Not performing fetch');
      return;
    }

    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        console.log(`Fetching data from ${url} and offset ${offset}`);
        const result = await ProPublicaApi.generateGetRequest(url, offset);

        if (result && result.ok) {
          result
            .json()
            .then(resp => {
              if (ProPublicaApi.isErrorResp(resp)) {
                console.log(`Error with req`);
                throw ProPublicaApi.getErrorFromResp(resp);
              } else {
                console.log(`req was successful: `);
                console.log(resp);
                const results = resp.results[0];
                setResults(results);
              }
            })
            .catch(err => {
              throw err;
            });
        } else {
          throw CommonUtils.getErrorFromFailedFetchResult(result);
        }
      } catch (error) {
        console.log(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url, offset]);

  return [{ results, offset, isLoading, isError }, setUrl, setOffset];
};

export default useProPublicaFetchData;
