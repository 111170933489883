import React from 'react';
import styles from './holyGrailPageLayout.module.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function HolyGrailPageLayout({ children }) {
    return (
        <div className={styles.layoutContainer} >
            <div className={styles.header} >
                <Header />
            </div>
            <div className={styles.mainContent} >
                {children}
            </div>
            <div className={styles.footer} >
                <Footer />
            </div>
        </div>       
    )
}

export default HolyGrailPageLayout;
