import React from 'react';
import styles from './billCard.module.scss';
import DataWithLabel from '../DataWithLabel';
import ContactCard from '../ContactCard';
//import tempLogo from '../../assets/images/ic_assignment_blue.png';
import internetLogo from '../../assets/images/ic_action_ios7_world.png';
import { CommonUtils, GeoUtils } from '../../utils';

function BillCard(props) {
  const shortTitle = props.bill.short_title;
  const fullTitle = props.bill.title;

  const billNumber = props.bill.number;
  // const summaryShort = props.bill.summary_short;
  const summary = props.bill.summary;
  const committees = props.bill.committees;
  const active =
    props.bill.active === undefined || props.bill.active === null
      ? null
      : props.bill.active.toString();
  const lastMajorAction = props.bill.latest_major_action;
  const lastMajorActionDate = props.bill.latest_major_action_date;
  const dateIntroduced = props.bill.introduced_date;
  const numberOfCosponsors = props.bill.cosponsors;
  const sponsorNameAndTitle = `${props.bill.sponsor_title || ''} ${props.bill
    .sponsor_name || 'Sponsor name not available'}`;
  const sponsorState = GeoUtils.getStateFullName(props.bill.sponsor_state);
  const sponsorParty = CommonUtils.getPartyFromPrefix(props.bill.sponsor_party);
  const datePassedByHouse = props.bill.house_passage;
  const datePassedBySenate = props.bill.senate_passage;
  const dateEnacted = props.bill.enacted;
  const dateVetoed = props.bill.vetoed;

  const urlCongress = props.bill.congressdotgov_url;
  const urlGovTrack = props.bill.govtrack_url;

  const moreInfoArray = [
    {
      url: urlCongress,
      imgSrc: internetLogo,
      label: 'Congress.gov'
    },
    {
      url: urlGovTrack,
      imgSrc: internetLogo,
      label: 'GovTrack'
    }
  ];

  return (
    <div className={styles.cardContainer}>
      <div className={styles.titleContainer}>{shortTitle || fullTitle}</div>
      {/* <div className={styles.billIconParent}> */}
      {/* <img src={billLogo} className={styles.cardPortrait} alt="Bill Icon"></img> */}
      {/* </div> */}
      <div className={styles.detailsParentContainer}>
        <div className={styles.detailsContainer}>
          <DataWithLabel
            alwaysStackItems={true}
            label="Bill Number"
            text={billNumber}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Active"
            text={active}
            capitalizeText={true}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date Introduced"
            text={dateIntroduced}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Sponsor"
            text={sponsorNameAndTitle}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Sponsor Party"
            text={sponsorParty}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Sponsor State"
            text={sponsorState}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Number of Co-Sponsors"
            text={numberOfCosponsors}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Committees"
            text={committees}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date Passed (House)"
            text={datePassedByHouse}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date Passed (Senate)"
            text={datePassedBySenate}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date Enacted"
            text={dateEnacted}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Date Vetoed"
            text={dateVetoed}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Last Major Action Date"
            text={lastMajorActionDate}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Last Major Action"
            text={lastMajorAction}
          />
          <DataWithLabel
            alwaysStackItems={true}
            label="Summary"
            text={summary}
          />
        </div>
      </div>
      <div className={styles.contactInfoParentContainer}>
        <ContactCard title="More Info" contactsArray={moreInfoArray} />
      </div>
    </div>
  );
}

export default BillCard;
