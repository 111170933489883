import React, { useState } from 'react';
import { LegislatorUtils } from '../../utils';
import styles from './legislatorImage.module.scss';
import republicanLogo from '../../assets/images/ic_republican_elephant.png';
import decomatricLogo from '../../assets/images/ic_democratic_logo.png';
import personLogo from '../../assets/images/ic_person_black.png';
import LazyLoad from 'react-lazy-load';

const LegislatorImage = props => {
  const [imageUrl, setImageUrl] = useState(
    LegislatorUtils.getImageUrl(props.legislator)
  );
  const [showPlaceHolder, setShowPlaceholder] = useState(true);
  const classToUse = props.className || styles.cardPortraitParent;

  const getDefaultLegislatorImg = function(legislator) {
    switch (LegislatorUtils.getParty(legislator).toLowerCase()) {
      case 'republican':
        return republicanLogo;
      case 'democrat':
        return decomatricLogo;
      case 'independent':
      default:
        return personLogo;
    }
  };

  const handleOnLoadingImgError = function(e) {
    console.log(`Error loading img src ${e.target.src}`);
    setImageUrl(getDefaultLegislatorImg(props.legislator));
  };

  return (
    <div className={classToUse}>
      <LazyLoad
        throttle={150}
        offsetVertical={1000}
        onContentVisible={() => {
          setShowPlaceholder(false);
        }}
      >
        <img
          src={imageUrl}
          onError={e => handleOnLoadingImgError(e)}
          alt="Legislator Portrait"
        ></img>
      </LazyLoad>
      {showPlaceHolder && (
        <img
          src={getDefaultLegislatorImg(props.legislator)}
          alt="Legislator Portrait"
        ></img>
      )}
    </div>
  );
};

export default LegislatorImage;
